export const SIDEBAR_COLLAPSE_KEY = 'sidebarCollapse';

export const StorageService = {
	getItem(STORAGE_KEY) {
		return window.localStorage.getItem(STORAGE_KEY);
	},

	saveItem(STORAGE_KEY, value) {
		window.localStorage.setItem(STORAGE_KEY, value);
	},

	destroyItem(STORAGE_KEY) {
		window.localStorage.removeItem(STORAGE_KEY);
	}
};

export default StorageService;
