import axios from 'axios';

import store from '@/store/';
import { AUTH_LOGOUT_LOCAL } from '@/store/actions.type';

import SanctumService from '@/services/sanctum.service';

const ApiService = {
	init() {
		axios.defaults.baseURL = process.env.VUE_APP_API_URL;
		this.setInterceptors();
	},

	setInterceptors() {
		axios.interceptors.request.use(
			(request) => {
				const token = SanctumService.getToken();
				if (token) request.headers['Authorization'] = 'Bearer ' + token;
				return request;
			},
			(error) => Promise.reject(error)
		);

		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response.status === 401) store.dispatch(AUTH_LOGOUT_LOCAL);
				return Promise.reject(error);
			}
		);
	},

	query(resource, params) {
		return axios.get(resource, { params: params }).then((res) => res.data).catch((error) => {
			throw new Error(`[QUERY] ApiService ${error}`);
		});
	},

	get(resource, slug = '') {
		const path = slug ? `${resource}/${slug}` : resource;
		return axios.get(path).then((res) => res.data).catch((error) => {
			throw new Error(`[GET] ApiService ${error}`);
		});
	},

	post(resource, params) {
		return axios.post(resource, params).then((res) => res.data).catch((error) => {
			throw new Error(`[POST] ApiService ${error}`);
		});
	},

	update(resource, slug, params) {
		return axios.put(`${resource}/${slug}`, params).then((res) => res.data).catch((error) => {
			throw new Error(`[UPDATE] ApiService ${error}`);
		});
	},

	put(resource, params) {
		return axios.put(resource, params).then((res) => res.data).catch((error) => {
			throw new Error(`[PUT] ApiService ${error}`);
		});
	},

	delete(resource) {
		return axios.delete(resource).then((res) => res.data).catch((error) => {
			throw new Error(`[DELETE] ApiService ${error}`);
		});
	}
};

export default ApiService;
