export default {
	install: (app) => {
		const Logger = (message, level = 'info') => {
			if (process.env.NODE_ENV === 'production') return;

			if (level === 'error') {
				console.error(message);
			} else if (level === 'warn') {
				console.warn(message);
			} else {
				console.log(message);
			}
		};

		app.provide('logger', Logger);
	}
};
