export const AUTH_REGISTER = 'register';
export const AUTH_LOGIN = 'login';
export const AUTH_LOGOUT = 'logout';
export const AUTH_LOGOUT_FORCE = 'forceLogout';
export const AUTH_LOGOUT_LOCAL = 'localLogout';
export const AUTH_CHECK = 'authenticate';

export const PASSWORD_RECOVER = 'recoverPassword';
export const PASSWORD_RESET = 'resetPassword';
export const PASSWORD_CHANGE = 'changePassword';

export const SIDEBAR_COLLAPSE = 'collapseSidebar';
export const SIDEBAR_HIDE = 'hideSidebar';

export const PREFERENCES_SIDEBAR = 'preferenceSidebar';
