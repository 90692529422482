import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

/* Chartkick */

import VueChartkick from 'vue-chartkick';
app.use(VueChartkick);

/* Axios */

import ApiService from '@/services/api.service';
ApiService.init();

/* Font Awesome */

import FontAwesome from '@/plugins/font-awesome';
FontAwesome(app);

/* Element Plus */

import ElementPlus from '@/plugins/element-plus';
ElementPlus(app);

/* Logger */
import Logger from '@/plugins/logger';
app.use(Logger);

/* Deploy App */

app.use(store).use(router).mount('#app');
