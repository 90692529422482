import { SET_AUTH, CONFIRM_AUTH, PURGE_AUTH } from '@/store/mutations.type';
import { AUTH_REGISTER, AUTH_LOGIN, AUTH_LOGOUT, AUTH_LOGOUT_FORCE, AUTH_LOGOUT_LOCAL, AUTH_CHECK, PASSWORD_RECOVER, PASSWORD_RESET, PASSWORD_CHANGE } from '@/store/actions.type';

import { routerPush } from '@/router';

import ApiService from '@/services/api.service';
import SanctumService from '@/services/sanctum.service';
import NotificationService from '@/services/notification.service';

const state = {
	token: SanctumService.getToken(),
	user: {}
};

const getters = {
	isLogged: (state) => !!state.token,
	isAuthenticated: (state) => Object.keys(state.user).length != 0 && state.user.constructor === Object,
	currentUser: (state) => state.user
};

const mutations = {
	[SET_AUTH](state, { token, user }) {
		SanctumService.saveToken(token);
		state.token = token;
		state.user = user;
	},

	[CONFIRM_AUTH](state, user) {
		state.user = user;
	},

	[PURGE_AUTH](state, isFullPurgeRequired = true) {
		if (isFullPurgeRequired) SanctumService.destroyToken();
		state.token = '';
		state.user = {};
	}
};

const actions = {
	[AUTH_REGISTER]({ commit }, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post('auth/activate', credentials)
				.then(({ data }) => {
					commit(SET_AUTH, data);
					resolve();
				})
				.catch((error) => {
					NotificationService.displayError('Nie udało się zarejestrować', 'Wystąpił nieoczekiwany błąd, spróbuj ponownie później.');
					reject(error);
				});
		});
	},

	[AUTH_LOGIN]({ commit }, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post('auth/login', credentials)
				.then(({ data }) => {
					commit(SET_AUTH, data);
					resolve();
				})
				.catch((error) => {
					NotificationService.displayError('Nie udało się zalogować', 'Wprowadzono błędny login lub hasło.');
					reject(error);
				});
		});
	},

	[AUTH_LOGOUT]({ commit }) {
		commit(PURGE_AUTH, false);
		routerPush('login');
		ApiService.get('auth/logout')
			.catch((error) => {
				return error;
			})
			.finally(() => {
				SanctumService.destroyToken();
			});
	},

	[AUTH_LOGOUT_FORCE]({ commit }) {
		commit(PURGE_AUTH, false);
		routerPush('login');
		ApiService.get('auth/forceLogout')
			.catch((error) => {
				return error;
			})
			.finally(() => {
				SanctumService.destroyToken();
			});
	},

	[AUTH_LOGOUT_LOCAL]({ commit }) {
		commit(PURGE_AUTH);
		routerPush('login');
	},

	[AUTH_CHECK]({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			ApiService.get('auth/user')
				.then(({ data }) => {
					commit(CONFIRM_AUTH, data.user);
					resolve();
				})
				.catch((error) => {
					dispatch(AUTH_LOGOUT_LOCAL);
					reject(error);
				});
		});
	},

	[PASSWORD_RECOVER](_context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post('auth/recover', credentials).then((response) => resolve(response)).catch((error) => {
				NotificationService.displayError('Nie udało się przypomnieć hasła', 'Wystąpił nieoczekiwany błąd, spróbuj ponownie później.');
				reject(error);
			});
		});
	},

	[PASSWORD_RESET](_context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post('auth/reset', credentials).then((response) => resolve(response)).catch((error) => {
				NotificationService.displayError('Nie udało się zresetować hasła', 'Wystąpił nieoczekiwany błąd, spróbuj ponownie później.');
				reject(error);
			});
		});
	},

	[PASSWORD_CHANGE](_context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.post('auth/changePassword', credentials).then((response) => resolve(response)).catch((error) => {
				NotificationService.displayError('Nie udało się zresetować hasła', 'Wystąpił błąd, sprawdź poprawność hasła i spróbuj ponownie później.');
				reject(error);
			});
		});
	}
};

export default { state, getters, mutations, actions };
