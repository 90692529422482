import { SET_SIDEBAR_COLLAPSE, SET_SIDEBAR_HIDE } from '@/store/mutations.type';
import { SIDEBAR_COLLAPSE, SIDEBAR_HIDE, PREFERENCES_SIDEBAR } from '@/store/actions.type';

import { SIDEBAR_COLLAPSE_KEY, StorageService } from '@/services/storage.service';

const state = {
	scrollDisable: false,
	isSidebarHidden: true,
	isSidebarCollapsed: StorageService.getItem(SIDEBAR_COLLAPSE_KEY) === 'true'
};

const getters = {
	isScrollDisabled: (state) => state.scrollDisable,
	isSidebarHidden: (state) => state.isSidebarHidden,
	isSidebarCollapsed: (state) => state.isSidebarCollapsed === true,
	isSidebarCollapsedPreference: () => StorageService.getItem(SIDEBAR_COLLAPSE_KEY) === 'true'
};

const mutations = {
	[SET_SIDEBAR_COLLAPSE](state) {
		if (!state.isSidebarHidden) state.isSidebarHidden = true;
		if (state.scrollDisable) state.scrollDisable = false;

		state.isSidebarCollapsed = !state.isSidebarCollapsed;
	},

	[SET_SIDEBAR_HIDE](state) {
		if (state.isSidebarCollapsed) state.isSidebarCollapsed = false;
		state.scrollDisable = !state.scrollDisable;
		state.isSidebarHidden = !state.isSidebarHidden;
	}
};

const actions = {
	[SIDEBAR_COLLAPSE]({ commit }) {
		commit(SET_SIDEBAR_COLLAPSE);
	},

	[SIDEBAR_HIDE]({ commit }) {
		commit(SET_SIDEBAR_HIDE);
	},

	[PREFERENCES_SIDEBAR]({ commit }, value) {
		commit(SET_SIDEBAR_COLLAPSE);
		StorageService.saveItem(SIDEBAR_COLLAPSE_KEY, value);
	}
};

export default { state, getters, mutations, actions };
