import {
	ElButton,
	ElRadio,
	ElForm,
	ElInput,
	ElContainer,
	ElMenu,
	ElCol,
	ElRow,
	ElCard,
	ElPopover,
	ElDescriptions,
	ElEmpty,
	ElTag,
	ElSelect,
	ElTabs,
	ElDatePicker,
	ElSkeleton,
	ElLoading,
	ElResult,
	ElSwitch,
	ElDialog
} from 'element-plus';

const components = [
	ElButton,
	ElRadio,
	ElForm,
	ElInput,
	ElContainer,
	ElMenu,
	ElCol,
	ElRow,
	ElCard,
	ElPopover,
	ElDescriptions,
	ElEmpty,
	ElTag,
	ElSelect,
	ElTabs,
	ElDatePicker,
	ElSkeleton,
	ElLoading,
	ElResult,
	ElSwitch,
	ElDialog
];

export default (app) => {
	components.forEach((component) => {
		app.use(component);
	});
};
