import { createStore } from 'vuex';

import settings from '@/store/settings.module';
import auth from '@/store/auth.module';

export default createStore({
	modules: {
		settings,
		auth
	}
});
