import { createRouter, createWebHistory } from 'vue-router';
import { checkResetPasswordHash, checkAuth } from '../middlewares/check-auth.js';

const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "group-default" */ '@/layouts/Default.vue'),
		children: [
			{
				name: 'login',
				path: '',
				component: () => import(/* webpackChunkName: "group-default" */ '@/views/Login.vue')
			},
			{
				name: 'register',
				path: 'rejestracja',
				component: () => import(/* webpackChunkName: "group-default" */ '@/views/Register.vue')
			},
			{
				name: 'recover-password',
				path: 'przypomnienie-hasla',
				component: () => import(/* webpackChunkName: "group-default" */ '@/views/PasswordRecover.vue')
			},
			{
				name: 'reset-password',
				path: 'przypomnienie-hasla/:hash',
				component: () => import(/* webpackChunkName: "group-default" */ '@/views/PasswordReset.vue'),
				beforeEnter: checkResetPasswordHash
			}
		]
	},
	{
		path: '/panel',
		meta: { requiresAuth: true },
		component: () => import(/* webpackChunkName: "group-authorized" */ '@/layouts/Authorized.vue'),
		children: [
			{
				name: 'home',
				path: '',
				component: () => import(/* webpackChunkName: "group-authorized" */ '@/views/Dashboard.vue')
			},
			{
				name: 'ad-details',
				path: 'szczegoly',
				component: () => import(/* webpackChunkName: "group-authorized" */ '@/views/Adverts.vue')
			},
			{
				name: 'company-details',
				path: 'dane',
				component: () => import(/* webpackChunkName: "group-authorized" */ '@/views/Company.vue')
			},
			{
				name: 'settings',
				path: 'ustawienia',
				component: () => import(/* webpackChunkName: "group-authorized" */ '@/views/Settings.vue')
			}
		]
	},
	{
		name: 'error',
		path: '/:catchAll(.*)',
		component: () => import(/* webpackChunkName: "group-default" */ '@/layouts/Error.vue')
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	checkAuth(to, next);
});

export function routerPush(name, params = null) {
	if (params === null) return router.push({ name });
	else return router.push({ name, params });
}

export default router;
