import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faMouse } from '@fortawesome/free-solid-svg-icons/faMouse';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';

library.add(faUser, faLock, faEnvelope, faKey, faHome, faChartBar, faAddressCard, faSignOutAlt, faChartLine, faChartArea, faEye, faMouse, faBars, faSearch, faCog, faCompass, faQuestion);

export default (app) => {
	app.component('font-awesome-icon', FontAwesomeIcon);
};
