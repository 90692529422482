import { h } from 'vue';
import 'element-plus/es/components/notification/style/css';
import { ElNotification, ElMessageBox } from 'element-plus';

const NotificationService = {
	displayError(title = 'Ups... Coś poszło nie tak.', message = 'Wystąpił nieoczekiwany błąd. Prosimy odswieżyć stronę lub spróbować ponownie później.') {
		ElNotification({
			type: 'error',
			title: title,
			message: message,
			duration: 3000
		});
	},

	displaySuccess(title = 'Sukces!', message = 'Pomyślnie wykonano żądaną operację.') {
		ElNotification({
			type: 'success',
			title: title,
			message: message,
			duration: 3000
		});
	},

	displayContactModal() {
		ElMessageBox({
			title: 'Kontakt z redakcją',
			message: h('div', { style: 'font-size: 16px; margin-bottom: 10px;' }, [
				h('div', { style: 'margin-bottom: 10px' }, 'W celu aktualizacji danych prosimy o kontakt z redakcją drogą telefoniczną lub mailową: '),
				h('span', { style: 'font-weight:bold' }, 'Telefon: '),
				h('i', { style: 'color: #1569b1' }, '13 493 44 44'),
				h('br'),
				h('span', { style: 'font-weight:bold' }, 'Mail: '),
				h('i', { style: 'color: #1569b1' }, 'redakcja@krosno24.pl')
			]),
			dangerouslyUseHTMLString: true,
			center: true,
			confirmButtonText: 'Zamknij',
			confirmButtonClass: 'is-plain'
		}).catch(() => {});
	}
};

export default NotificationService;
